import React from 'react'
import { useQuery } from '@apollo/client'

export const useQueryLatestData = (query, options) => {
  // Implementing this way using useState allows for "data"
  // to be cached/displayed even while Apollo is re-fetching
  // Without it, you will get content blinking in-out twice.
  const [latestData, setLatestData] = React.useState()
  const response = useQuery(query, options)

  React.useEffect(() => {
    if (!response.data) return
    setLatestData(response.data)
  }, [response.data])

  return { ...response, latestData }
}
